<!-- eslint-disable vue/component-name-in-template-casing -->
<template>
  <div class="custom-date-picker">
    <div class="custom-date-picker__title" v-if="title">{{ title }}</div>
    <date-picker
      v-model="date"
      value-type="format"
      format="DD/MM/YYYY"
      @change="handleChange"
      :placeholder="placeholder"
      :disabled-date="disableDates"
      :range="range"
      confirm
      :confirm-text="$gt('OK')"
    ></date-picker>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { useTranslation } from '~/composables';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/it';
import 'vue2-datepicker/index.css';

export default defineComponent({
  name: 'CustomDatePicker',
  components: {
    DatePicker,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    range: {
      type: Boolean,
      default: false,
    },
    inputDate: {
      type: [String, Array],
      default: null,
    },
    disableDates: {
      type: Function,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { $gt } = useTranslation();
    const date = ref(props.inputDate);

    watch(
      () => props.inputDate,
      (newDate) => {
        date.value = newDate;
      }
    );

    const handleChange = (d) => {
      emit('change', d);
    };

    return {
      date,
      handleChange,
      $gt,
    };
  },
});
</script>

<style lang="scss">
.mx-datepicker {
  width: 100%;
  &-popup {
    .cell.active {
      background-color: var(--c-primary);
    }
  }
  .mx-input {
    height: var(--form-element-mobile-height);
    border: var(--form-element-border);
    border-radius: unset;
  }
}
.custom-date-picker {
  @include flex-center;
  flex-direction: column;
  gap: 0.2rem;
  margin: 1rem 0;
  &__title {
    @include cta-text;
  }
}
@include from-desktop-min {
  .mx-datepicker {
    .mx-input {
      height: var(--form-element-desktop-height);
      border: var(--form-element-border);
      border-radius: unset;
    }
  }
}
</style>
