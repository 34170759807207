export { default as ArrowDown } from '~/static/icons/ArrowDown.svg';
export { default as ArrowLeftIcon } from '~/static/icons/ArrowLeft.svg';
export { default as BackChevron } from '~/static/icons/back_chevron.svg';
export { default as ColfertLogo } from '~/static/icons/logo.svg';
export { default as Cross } from '~/static/icons/Cross.svg';
export { default as PlusIcon } from '~/static/icons/plus.svg';
// Checkout
export { default as PaymentCreditCardIcon } from '~/static/checkout/payment-credit-card-icon.svg';
export { default as PaymentPaypalIcon } from '~/static/checkout/payment-paypal-icon.svg';
export { default as PaymentEmptyIcon } from '~/static/checkout/payment-empty-icon.svg';
export { default as CheckGreen } from '~/static/checkout/check-green.svg';
// PDP
export { default as AddToCartIcon } from '~/static/pdp/AddToCart.svg';
export { default as AddToWishlistIcon } from '~/static/pdp/AddToWishlist.svg';
export { default as DownloadDocumentationIcon } from '~/static/pdp/DownloadDocumentation.svg';
// Home after login show section
export { default as MyFavorites } from '~/static/homepage/my-favorites.svg';
export { default as MyDocuments } from '~/static/homepage/my-documents.svg';
export { default as MyCart } from '~/static/homepage/my-cart.svg';
export { default as DownloadArea } from '~/static/homepage/download-area.svg';
export { default as MyData } from '~/static/homepage/my-data.svg';
// Header menu
export { default as HeaderLogo } from '~/static/icons/header-logo.svg';
export { default as RightArrow } from '~/static/header/right-arrow.svg';
export { default as MenuBack } from '~/static/header/menu-back.svg';
export { default as MenuClose } from '~/static/header/menu-close.svg';
export { default as BackCheckoutIcon } from '~/static/header/menu-back.svg';
export { default as HamburgerIcon } from '~/static/icons/humburger-icon.svg';
export { default as LoginIcon } from '~/static/productpage/login-icon.svg';
export { default as WishlistIcon } from '~/static/productpage/wishlist-icon.svg';
export { default as WishlistIconFill } from '~/static/productpage/wishlist-icon-fill.svg';
export { default as WishlistBlackIcon } from '~/static/icons/wishlist-fill.svg';
export { default as CartIcon } from '~/static/productpage/cart-icon.svg';

// Slider arrow
export { default as SliderLeftArrow } from '~/static/homepage/left-arrow.svg';
export { default as SliderRightArrow } from '~/static/homepage/right-arrow.svg';
export { default as ArrowWhiteGo } from '~/static/homepage/arrow-white-icon.svg';
export { default as LongRightArrow } from '~/static/icons/long-right-arrow.svg';

// Home Page news Section
export { default as ArrowIcon } from '~/static/homepage/arrow-icon.svg';
export { default as InfoIcon } from '~/static/homepage/info-icon.svg';
export { default as InfoIconBlack } from '~/static/icons/info-icon-black.svg';
